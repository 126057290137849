import request from '@/http/request'
import wayCode from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config';
import service from '../utils/request';

/** 获取url **/
export function getRedirectUrl () {
  return request.request({
    url: '/api/cashier/redirectUrl',
    method: 'POST',
    data: { wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken }
  })
}

/** 获取url **/
export function getChannelUserId (redirectData) {
  return request.request({
    url: '/api/cashier/channelUserId',
    method: 'POST',
    data: Object.assign({ wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken }, redirectData)
  })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage (amount) {
  return request.request({
    url: '/api/cashier/pay',
    method: 'POST',
    data: {
      wayCode: wayCode.getPayWay().wayCode,
      token: config.cacheToken,
      amount: amount,
      channelUserId: channelUserIdUtil.getChannelUserId()
    }
  })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo () {
  return request.request({
    url: '/api/cashier/payOrderInfo',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}

/** 取消订单 **/
export function cancelPay () {
  return request.request({
    url: '/api/cashier/cancelPay',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}

/**
 * 简易聚合支付订单
 */
 export function qrCashierOrder (params) {
  return service({
    url: '/api/pay/qrCashierOrder',
    method: 'GET',
    params: {
      ...params,
      token: config.cacheToken
    }
  })
}

/** 调起支付接口, 获取订单信息 **/
export function createQrCashierOrder (data) {
  return request.request({
    url: '/api/pay/qrCashierOrderUrl',
    method: 'POST',
    data
  })
}
/**厦门国际-个人开户 */
export function registerEntAcct (data) {
  return request.request({
    url: '/api/xib/register_ent_acct',
    method: 'POST',
    data:{...data,token: config.cacheToken}
  })
}
/**厦门国际-对公开户 */
export function registerMchAcct (data) {
  return request.request({
    url: '/api/xib/register_mch_acct',
    method: 'POST',
    data:{...data,token: config.cacheToken}
  })
}
/**上传文件 */
export function uploadFile(formData) {
  return request.request({ 
      url: '/api/anon/upload', 
      method: 'POST',
      data:formData,
      headers:{
        itoken: config.cacheToken
      }
  })
}
/**厦门国际-新增提现账户 */
export function addWithdrawAccount (data) {
  return request.request({
    url: '/api/xib/dict/add_withdraw_account',
    method: 'POST',
    data:{...data,token: config.cacheToken}
  })
}

/**厦门国际-银行列表 */
export function bankList () {
  return request.request({
    url: '/api/xib/dict/bank_list',
    method: 'POST',
    data:{token: config.cacheToken}
  })
}

/**厦门国际-提现账户详情 */
export function bindCard (params) {
  return request.request({
    url: '/api/xib/dict/bind_card',
    method: 'POST',
    params: {
      ...params,
      token: config.cacheToken
    }
  })
}
/**厦门国际-提现账户激活 */
export function bindCardActivate (params) {
  return request.request({
    url: '/api/xib/dict/bind_card',
    method: 'POST',
    params: {
      ...params,
      token: config.cacheToken
    }
  })
}

/**厦门国际-绑卡验证 */
export function bindCardVerify (data) {
  return request.request({
    url: '/api/xib/dict/bind_card_verify',
    method: 'POST',
    data:{...data,token: config.cacheToken}
  })
}
/**厦门国际-查询个人开户 */
export function getRegisterEntAcct (params) {
  return request.request({
    url: '/api/xib/register_ent_acct',
    method: 'GET',
    params: {
      ...params,
      token: config.cacheToken
    }
  })
}
//demo查询退款订单
export function refundQuery (params) {
  return request.request({
    url: '/api/demo/refund/query',
    method: 'POST',
    params: {
      ...params
    }
  })
}
//demo统一退款
export function refundOrder(data) {
  return request.request({
    url: '/api/demo/refund/refundOrder',
    method: 'POST',
    data
  })
}
//demo统一下单
export function unifiedOrder (data) {
  return request.request({
    url: '/api/demo/unifiedOrder',
    method: 'POST',
    data
  })
}
//demo查询支付订单
export function unifiedOrderQuery (params) {
  return request.request({
    url: '/api/demo/unifiedOrder/query',
    method: 'POST',
    params: {
      ...params
    }
  })
}